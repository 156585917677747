<template>
  <el-col :span="spanWidth">
    <div class="detail-content">
      <span class="left-label" :style="{ width: maxWidth }">{{ leftLabel }}:</span>
      <span>{{ rightValue }}</span>
    </div>
  </el-col>
</template>

<script>
export default {
  name: "DetailItem",
  data() {
    return {};
  },
  props: {
    spanWidth: {
      type: Number,
      default: 8
    },
    leftLabel: {
      type: String,
      default: ""
    },
    rightValue: {
      type: String,
      default: ""
    },
    maxWidth: {
      type: String,
      default: "130px"
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.detail-content {
  font-size: 15px;
  color: #666;
  padding: 10px 0;
  margin-left: 10px;
  display: flex;
  align-items: center;

  .left-label {
    display: inline-block;
    text-align: right;
    margin-right: 16px;
  }
}
</style>